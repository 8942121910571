<template>
  <v-container fluid>
    <v-card>
      <v-toolbar flat color="primary" dark>
        <v-toolbar-title>Receipts</v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <v-row>
          <v-col cols="12" sm="6" md="4">
            <v-select
              v-model="selectedStatus"
              :items="statuses"
              label="Filter by Status"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-btn color="primary" @click="getReceipts">Filter</v-btn>
          </v-col>
        </v-row>

        <v-data-table
          :headers="headers"
          :items="formattedReceipts"
          class="elevation-1 mt-4"
        >
          <template v-slot:item.receiptImage="{ item }">
            <v-img
              :src="item.receiptImage"
              max-width="100"
              max-height="100"
              @click="openDialog(item.receiptImage)"
              class="clickable"
            ></v-img>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-row no-gutters>
              <v-col>
                <v-btn
                  class="mr-2"
                  small
                  color="green"
                  @click="updateReceiptStatus(item, 'approved')"
                  >Approve</v-btn
                >
              </v-col>
              <v-col>
                <v-btn
                  small
                  color="red"
                  @click="updateReceiptStatus(item, 'rejected')"
                  >Reject</v-btn
                >
              </v-col>
            </v-row>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-card-title class="headline">Receipt Image</v-card-title>
        <v-card-text class="text-center">
          <v-img :src="selectedImage" max-width="100%"></v-img>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import RestResource from "../../../services/dataServiceMobileV2";
import moment from 'moment';
const service = new RestResource();

export default {
  data() {
    return {
      receipts: [],
      dialog: false,
      selectedImage: "",
      statuses: ["pending", "approved", "rejected"],
      selectedStatus: "pending",
      headers: [
        { text: "Email", value: "email" },
        { text: "User ID", value: "userId" },
        { text: "Submission Id", value: "_id" },
        { text: "Status", value: "status" },
        { text: "Receipt Image", value: "receiptImage" },
        { text: "Points Awarded", value: "pointsAwarded" },
        { text: "Created At", value: "createdAt" },
        { text: "Actions", value: "actions", sortable: false },
      ],
    };
  },

  computed: {
    formattedReceipts() {
      return this.receipts.map(receipt => ({
        ...receipt,
        createdAt: moment(receipt.createdAt).format('DD-MMM-YYYY')
      }));
    }
  },

  mounted() {
    this.getReceipts();
  },

  methods: {
    getReceipts() {
      this.$setLoader();
      service.getReceiptData({ status: this.selectedStatus }).then((r) => {
        this.receipts = r.data;
        this.$disableLoader();
      });
    },

    updateReceiptStatus(item, status) {
      let dataToSend = {
        status,
        submissionId: item._id,
        userId: item.userId,
      };
      this.$setLoader();
      service.updateReceiptStatus(dataToSend).then(() => {
        this.getReceipts();
      });
      this.$disableLoader();
    },

    openDialog(imageUrl) {
      this.selectedImage = imageUrl;
      this.dialog = true;
    },
  },
};
</script>

<style scoped>
.v-img.clickable {
  cursor: pointer;
}
</style>
